.card {
  border: none !important;
  vertical-align: middle !important;

  &:hover {
      box-shadow: 0px 8px 15px $shadow-color;
  }

  &.dark-theme {
      background-color: $dark-card-bg !important;
      color: $dark-text-color !important;
  }

  &.light-theme {
      background-color: $light-card-bg !important;
      color: $light-text-color !important;
  }
}

.card-title{
  font-size: $h5-size !important;

}

.table{
  --bs-table-bg: $primary-color; 
  --bs-table-color: $dark-text-color ; 
  font-size: $p-size;
  vertical-align: middle !important;


  &.dark-theme {
    background-color: $dark-bg !important;
    color: $dark-text-color !important;
    border-bottom-color: $secondary-color !important ;
    border-radius: 8px !important;

  }
    &.light-theme {
      background-color: $light-card-bg !important;
      color: $light-text-color !important;
  }
}

tbody, td, tfoot, th, thead, tr{
  border-style: none !important;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  vertical-align: middle !important;

  &.dark-theme {
    border-color: $secondary-color !important;

  }
}

.card-header{
  border-bottom: none !important;
  background-color: transparent !important;
  padding: 4%;
  &.dark-theme{
  background-color: $dark-bg !important;
}
}

.list-group{
    vertical-align: middle !important;
    background-color: transparent;
    width: 100%;
    --bs-list-group-active-bg: #121323;
    border-radius: 8px;

  &.dark-theme{
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom-color: $secondary-color !important ;
    border-bottom-style: 1px !important;
    --bs-list-group-action-hover-bg: #1213232c !important;

  }  
  &.hover{
    --bs-list-group-action-hover-bg: #1213232c !important;
    
  }

}

.col{
  align-self: center;
}

.list-group-item-action {
  background-color: transparent;
      --bs-list-group-action-hover-color: none; 
     --bs-list-group-action-hover-bg: none;
     --bs-list-group-action-active-bg: none;
     border-radius: 8px;
     --bs-list-group-border-color: none;
    
  &.dark-theme{
    border-bottom-color: $secondary-color !important ;
    --bs-list-group-action-hover-bg: none !important;
    color: $light-icon-opacity !important;

  }
}

.card-text{
  padding-bottom: 4% !important;
}

.list-group-item{

&.dark-theme{
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom-color: $secondary-color !important ;
  border-bottom-style: 1px solid !important;
  --bs-list-group-action-hover-bg: #1213232c !important;

}  

}