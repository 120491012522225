  h1 { font-size: $h1-size; font-weight:700; opacity: 1; letter-spacing: -0.05rem; line-height: 140%; &.dark-theme {color: $dark-text-color !important;}&.light-theme {color: $light-text-color !important;}}
  h2 { font-size: $h2-size; font-weight:700; opacity: 1; letter-spacing: -0.05rem; line-height: 140%; &.dark-theme {color: $dark-text-color !important;}&.light-theme {color: $light-text-color !important;}}
  h3 { font-size: $h3-size; font-weight:700; opacity: 1; letter-spacing: -0.05rem; line-height: 140%; &.dark-theme {color: $dark-text-color !important;}&.light-theme {color: $light-text-color !important;}}
  h4 { font-size: $h4-size; font-weight:700; opacity: 1; letter-spacing: -0.05rem; line-height: 140%; &.dark-theme {color: $dark-text-color !important;}&.light-theme {color: $light-text-color !important;}}
  h5 { font-size: $h5-size; font-weight:600; opacity: 1; line-height: 140%; &.dark-theme {color: $dark-text-color !important;}&.light-theme {color: $light-text-color !important;}}
  
  p, input {padding: 0; margin: 0; font-size: $p-size; line-height: 170%; opacity: 0.7; font-weight:400; letter-spacing: -0.001rem;  &.dark-theme {color: $dark-text-color !important;} &.light-theme {color: $light-text-color !important;}}
  
  span, text, label { font-size: $span; color: $lines !important; font-weight:600; opacity: 0.6; }

  td {font-size: $p-size; font-weight:300; &.dark-theme {color: $dark-text-color !important;}&.light-theme {color: $dark-text-color !important;}}

  a, link, li, input { font-size: $span !important; }

  a{
    color: #010101 !important;
    font-style: none !important;
    text-decoration: none  !important;
  }