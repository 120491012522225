
  .icon {
    opacity: $icon-opacity;
  }
  
  .dark-theme, .bg-dark {
    background-color: $dark-text-color !important;
    color: $dark-text-color !important;
    
  }


  