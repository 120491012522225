.btn-primary, .primary-btn {
  background-color: black !important;
  font-size: 14px !important;
  vertical-align: middle;
  font-weight: 500;
  padding-left: 3% !important;
  padding-right: 3% !important;
  border-radius: 7px !important;
  color: white !important;
  --bs-btn-border-color: transparent;
  max-height: 38px !important;
  width: 100%;
  border: none !important;

    &.dark-theme{
    background-color: $dark-card-bg !important;
    border-color: $light-bg !important;
    color: $dark-text-color !important;
  }
  &:disabled {
    background-color: $icon-color !important;
    font-size: 14px !important;
    font-weight: 500;
    max-height: 38px !important;

    border: none !important;
  }
  &:hover{
    border: none !important;
    max-height: 38px;
    font-size: 14px !important;

  }

}

.dropdown-toggle{
padding-left: 10px !important;  
padding-right: 10px !important; 
border-color: $dark-card-bg !important;
background-color: transparent !important;
color: $dark-card-bg !important;
}

.btn-secondary{
  border-color: $dark-card-bg !important;
  background-color: transparent !important;
  color: $dark-bg!important ;
  max-height: 38px !important;
  width: 100%;
  font-size: 14px !important;
  border-radius: 7px !important;
  border: solid 2px;
  &.dark-theme{
    border-color: $light-bg !important;
    color: $dark-text-color!important;
  }
  &:hover{
    
    color: $dark-text-color !important;
    background-color: $dark-card-bg !important;
    }
&:active{
  color: $dark-text-color !important;

}
&:focus{
  color: $dark-text-color !important;

}
&::selection{
  color: $dark-text-color !important;

}
    
}

.btn-success{

  background-color: $icon-color !important;
  border: none !important; 
  font-size: 14px !important;
  color: white !important;
  font-weight: 500;
  max-height: 38px;
  border-radius: 400px !important;

  width: 100%;
  &:disabled {
    background-color: $icon-color !important;
    font-size: 14px !important;
    font-weight: 500;
    max-height: 38px !important;

    border: none !important;
  }
}

input, .btn-outline-secondary, .btn-outline-primary {
  background-color: transparent !important;
  border-color:  #E4E4E7 !important;
  font-size: 14px !important;
  border-radius: 7px !important;
  color: #2b275f !important;
  
  &:focus {
    border-color: $light-icon-opacity !important;
    transition: none !important;
    appearance: none;
    outline: none !important; // Elimina el borde de enfoque predeterminado
    box-shadow: none !important; // Elimina cualquier sombra de enfoque que pueda aparecer
  color: #2b275f !important;

  }
  &:hover{
  background-color: transparent !important;
  color: #2b275f !important;

  }

    &.dark-theme{
    background-color: transparent !important;
  }
  
}

.input-group{
  background-color: white !important;
  &.dark-theme{
    background-color: $dark-card-bg !important;
    color: $dark-text-color!important;
  }
}


hr{

    background-color: transparent !important;
    border-color: $lines !important;
  
}



button.btn {
  --bs-btn-color: none !important; 
  max-height: 38px;
  font-size: 14px !important;
  
  &.dark-theme{
    color: $light-icon-opacity !important;
    }
}
//icono dark-light
.css-i4bv87-MuiSvgIcon-root{
  fill: $lines !important;
}

.btn{
  --bs-btn-padding-x: 0.3rem;
    --bs-btn-padding-y: 0.375rem;
    &:focus {
      border-color: $light-icon-opacity !important;
      transition: none !important;
      appearance: none;
      outline: none !important; // Elimina el borde de enfoque predeterminado
      box-shadow: none !important; // Elimina cualquier sombra de enfoque que pueda aparecer
      color: $dark-card-bg;
    }

}


.btn-light, .btn-light:focus, .btn-light:hover, .btn-light:active {
  --bs-btn-active-bg: none !important;
  --bs-btn-bg:  none !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  border-radius: 7px !important;
  color: $lines !important;
  border-color: #aeabc95e !important ;
  font-size: 14px !important;
  
  &.dark-theme{
    color: $dark-bg !important;
  background-color: $dark-card-bg !important;

    }
}


.btn-info, .btn-info:focus, .btn-info:hover, .btn-info:active{
  background-color: $dark-card-bg;
  --bs-btn-active-bg: none !important;
  border-radius: 7px !important;

  font-weight: 400;
  color: $lines !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  border-color: $dark-card-bg !important ;
  font-size: 14px !important;
  &.dark-theme{
    color: $dark-bg !important;
    background-color: $dark-card-bg !important;
    border-color: #6d6b8c40 !important ;

    }

}

.dropdown-menu{
  --bs-dropdown-link-active-bg: $light-bg !important;
  color: $dark-bg !important;

}

.dropdown-item.active{
  --bs-dropdown-link-active-bg: #f8f9fa !important;
  border-radius: 0px;
  background-color: #04020e15!important;
  align-content: center;
  color:$dark-bg ;
}


.form-control, .btn-outline-secondary{
  background-color: #d2d1de;
  border: solid 2px;
  color: #2b275f !important;
  &:focus {
    border-color: $light-icon-opacity !important;
    transition: none !important;
    appearance: none;
    outline: none !important; // Elimina el borde de enfoque predeterminado
    box-shadow: none !important; // Elimina cualquier sombra de enfoque que pueda aparecer
  color: #2b275f !important;

  }
  &.dark-theme{

    color: $dark-text-color;

    }
}

.form-suscriber{
  background-color: white !important;
  &.dark-theme{

    color: $dark-text-color !important;
    background-color: $dark-card-bg !important;

    }
}

.border-input{
//  border: solid 1px !important;
  border-color: #E4E4E7 !important;
  background-color: #ffffff !important;

}

.custom-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.custom-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2b275f;
  transition: 0.4s;
  border-radius: 25px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 2.5px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #6d6b8c; /* Color primario del tema */
}

input:focus + .slider {
  box-shadow: 0 0 1px #6d6b8c;
}

input:checked + .slider:before {
  transform: translateX(25px); /* Mover el círculo a la derecha */
}

/* Estilos adicionales para temas oscuros */
.dark-theme .custom-switch .slider {
  background-color: #6d6b8c;
}

.dark-theme input:checked + .slider {
  background-color: #6d6b8c; /* Color de éxito en tema oscuro */
}

.switch-label {
  font-size: 14px;
  color: inherit; /* Mantener el color del texto acorde al tema */
}
