
.nav-link {
        font-size: 14px !important;
        font-weight: 300 !important;
        --bs-nav-link-color: $dark-text-color !important ;

&.light-theme {
     color: $light-text-color !important;
     opacity: 0.9 !important;

 
} &.dark-theme {
        color: $dark-text-color !important;
        opacity: 0.6 !important;
       }
&:hover{
        color: $lines !important;
      
}
&:active{
        color: $dark-text-color !important;
      
}
}