html {
  min-height: 100%;
  position: relative;
}
body {
    font-family: $font-family;
    background-color: #f8f9fa  !important;


    &.dark-theme {
      background-color: #04020e !important;
      color: $light-icon-opacity !important;
    }
  }
.center-align{

  align-content: center !important;
}
  .footeer{

    bottom: 0;
    width: 100%;
    &.dark-theme {
      background-color: #04020e !important;
      color: $light-icon-opacity !important;
    }
    
  }

  /* Estilo general para la scrollbar */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px; /* Ancho de la scrollbar */
}

/* Pista de la scrollbar (background) */
.custom-scrollbar::-webkit-scrollbar-track {
  
  background: #f1f1f1; /* Color de la pista */
  border-radius: 10px; /* Bordes redondeados */
}

/* Manija de la scrollbar (thumb) */
.custom-scrollbar::-webkit-scrollbar-thumb {
  
  background: $lines; /* Color de la manija */
  border-radius: 10px; /* Bordes redondeados */
}

/* Manija al pasar el mouse */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  
  background: $light-bg; /* Color al hacer hover */
}

/* Ocultar las flechas de la scrollbar */
.custom-scrollbar::-webkit-scrollbar-button {
  
  display: none; /* Ocultar flechas */
}
