.badge{
    border-style: solid !important;
    border: 1px;
    border-color: $shadow-color;
    border-radius: 8px;
    background-color: transparent !important;
    color:$dark-bg;
    padding: 1%;
    font-size: $p-size;
    font-weight: 400 !important;
    margin-bottom: 2%;
    &.dark-theme {
        border: 1px $light-bg !important;
        border-color: $light-bg !important;
        color: $light-bg !important;
        border-color: #f8f9fa50  ;

    }
}

