// Colors
$primary-color: #04020e;
$secondary-color: lighten($primary-color, 20%);
$font-color: rgba($primary-color, 0.75);
$icon-color: rgba($primary-color, 0.40);
$shadow-color: rgba($primary-color, 0.08);
$lines: #676767;

// Font Sizes
$h1-size: 42px;
$h2-size: 38px; 
$h3-size: 31px;
$h4-size: 20px;
$h5-size: 16px;
$p-size: 14px;
$span: 12px;

// Spacing
$border-radius: 4px;
$button-padding: 0.75rem 1.25rem;

// Shadows
$card-shadow-hover: 0px 2px 10px $shadow-color;

// Other Variables
$font-family: 'Poppins', sans-serif !important;


// Dark Mode Variables
$dark-bg: #04020e;
$dark-card-bg: #121323;
$dark-text-color: rgba(255, 255, 255, 0.75);
$dark-icon-opacity: 0.4;

// Light Mode Variables
$light-bg: #f8f9fa !important;
$light-card-bg: #ffffff;
$light-text-color: #010101;
$light-icon-opacity: 0.7;

// Modo Oscuro - Fondo
$bg-color: $light-bg;
$card-bg-color: $light-card-bg;
$text-color: $light-text-color;
$icon-opacity: $light-icon-opacity;

// Fuentes
$font-family:  "Public Sans", sans-serif;
