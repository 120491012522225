.login-container { 
    align-content: center;
  }

  .login-image {
    height: 94vh;
    
    @media (max-width: 768px) {
      display: none;
    }
  }
  

  .login-form{
    align-content: center;
  }

